import { render, staticRenderFns } from "./NewPost.vue?vue&type=template&id=cd8e71c6&"
import script from "./NewPost.vue?vue&type=script&lang=js&"
export * from "./NewPost.vue?vue&type=script&lang=js&"
import style0 from "./NewPost.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports