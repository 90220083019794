<template>
  <div style="max-width: 100%">
    <card shadow header-classes="bg-transparent" body-classes="post-body" v-if="post!=null">
      <div slot="header" class="row align-items-center">
        <span class="gradient-info post-header">
          {{ post.title }}
        </span>
      </div>

      <div class="post-meta" v-if="$route.name==='post'">
        <div class="post-meta-item-default">
          <a href="#">{{ post.username }}</a>
        </div>
        <div class="post-meta-item-default">
          <a href="#"><badge type="primary"> {{ post.cate_name }} </badge></a>
        </div>
        <div class="post-meta-item-default">
          {{ post.c_t | formatDate }}
        </div>
        <div class="post-meta-item-default">
          浏览数 {{ post.views }}
        </div>
        <div class="post-meta-item-default">
          <span v-for="(tag, index) in post.tags" :key="index"><badge type="success">#{{tag}}</badge> {{' '}}</span>
        </div>
      </div>
      <div class="mb-0">
        <lone-mark :content="post.text"></lone-mark>
      </div>
    </card>
    <br/>
  </div>
</template>

<script>
import {getPostByAlias} from "../../api";
import {Notify, formatDate} from "../../utils";

export default {
  name: "blog-post",
  data() {
    return {
      post: null,
      text: "i am a ~~tast~~ **test**.",
      name: null
    }
  },
  created() {
    this.getPost();
  },
  watch: {
    $route() {
      this.getPost();
    }
  },
  filters: {
    formatDate
  },
  methods: {
    getPost() {
      getPostByAlias(this.$route.params.alias).then(res => {
        if (res.data.code === 0) {
          this.post = res.data.data;
        } else {
          Notify.error(res.data.code + ": " + res.data.msg)
        }
      })
    }
  }
};
</script>

<style>
.post-header {
  color: #5e72e4;
  font-size: 1.625rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

/*.card-header {*/
/*    padding: 0.75rem 1.5rem;*/
/*    margin-bottom: 0;*/
/*    background-color: #fff;*/
/*    border-bottom: 1px solid rgba(0, 0, 0, 0.05);*/
/*}*/

.post-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.75rem 1.5rem;
}

/*.card-footer {*/
/*    padding: 0.75rem 1.5rem;*/
/*    background-color: #fff;*/
/*    border-top: 1px solid rgba(0, 0, 0, 0.05);*/
/*}*/

.post-detail-icon {
  font-size: medium;
  margin-right: 0.5rem;
}

.post-detail-icon-text {
  margin-top: 10rem;
}

/*p code, h1 code, h2 code, h3 code, h4 code, h5 code {*/
/*  font-size: inherit;*/
/*  font-weight: 600;*/
/*  color: #32325d;*/
/*  word-wrap: break-word;*/
/*}*/

/*.hljs {*/
/*  color: #24292e;*/
/*  background-color: #FFF;*/
/*  border: 1px solid #ccc;*/
/*  border-radius: 5px;*/
/*  word-break: break-all;*/
/*  word-wrap: break-word;*/
/*}*/

/*img {*/
/*  vertical-align: middle;*/
/*  border-style: none;*/
/*  max-width: 100%;*/
/*}*/

.badge {
  display: inline-block;
  padding: 0.2rem 0.275rem;
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.post-meta {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.post-meta-item {
  display: inline-block;
}

.post-meta-item-default {
  display: inline-block;
  font-size: .75rem;
}

.post-meta-item-default::before {
  content: "•";
  color: #2dce89;
  text-shadow: #2dce89 0 0 5px;
  margin: 0 10px;
}

</style>