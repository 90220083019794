<template>
  <div>
    <base-header type="gradient-success" class="pb-8 pt-5">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">文章发布</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button size="sm" type="info" v-if="items.type === 1" @click="publish(0)">保存草稿</base-button>
                  <base-button size="sm" type="success" v-if="items.type === 1" @click="publish(1)">确认发布</base-button>
                  <base-button size="sm" type="success" v-if="items.type === 2" @click="publish(1)">更新发布</base-button>
                  <base-button size="sm" type="warning" v-if="items.type === 2" @click="backspace">取消</base-button>
                </div>
              </div>
            </div>
            <template>
              <form>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                          <base-input alternative="" label="标题">
                            <input v-model="post.title" ref="title" class="form-control" placeholder="请输入标题">
                          </base-input>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                          <base-input alternative="" label="别名">
                            <input v-model="post.alias" ref="alias" class="form-control" placeholder="请输入别名">
                          </base-input>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <base-input alternative="" label="正文">
                        <base-button v-show="items.textStatus==0" size="sm" type="success" outline @click="tooglePreview(1)" style="margin-left: 20px;">预览</base-button>
                        <base-button v-show="items.textStatus==1" size="sm" type="info" outline @click="tooglePreview(0)" style="margin-left: 20px;">编辑</base-button>
                        <textarea v-model="post.text" ref="text" rows="20" class="form-control" v-show="items.textStatus==0"
                                  placeholder="请输入正文..."></textarea>
                        <div class="preview-wrapper" v-show="items.textStatus==1">
                          <div class="preview">
                            <lone-mark :content="post.text"></lone-mark>
                          </div>
                        </div>
                      </base-input>
                    </div>
                    <div class="row">
                      <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                          <base-input alternative="" label="分类">
                            <lone-select v-model="post.cate_id" :options="items.categoryList" hint="请选择分类" opkey="value"
                                     oplabel="text"/>
                          </base-input>
                        </div>
                        <div class="form-group">
                          <base-switch before="默认" after="置顶" :value="post.position"
                                       @click="switchPosition"></base-switch>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-12">
                        <div class="form-group">
                          <base-input alternative="" label="标签">
                            <lone-tags-input :tags="post.tags" placeholder="输入标签"></lone-tags-input>
                          </base-input>
                        </div>
                        <div class="form-group">
                          <base-switch before="普通可见" after="管理员可见" :value="post.visible"
                                       @click="switchVisible"></base-switch>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import {getAllCategory, getTagList, savePost, getPostByAlias} from "../../api";
  import {Notify, isEmpty} from "../../utils";

  export default {
    data() {
      return {
        items: {
          // 1=新增, 2=编辑
          type: 1,
          categoryList: [
            {value: null, text: '请选择分类'}
          ],
          tag: '',
          tags: [],
          existTags: [],
          textStatus: 0    // 0-编辑, 1-预览
        },
        post: {
          title: null,
          alias: null,
          text: '',
          cate_id: null,
          position: 0,
          visible: 0,
          tags: [],
          status: null,
          type: 0
        }
      }
    },
    computed: {
      filteredItems() {
        return this.items.existTags.filter(i => {
          return i.text.toLowerCase().indexOf(this.items.tag.toLowerCase()) !== -1;
        });
      },
    },
    created() {
      this.initCategoryList();
      this.initTagItems();
      if (this.$route.name === 'editPost') {
        this.items.type = 2;
        this.initPost(this.$route.params['alias']);
      }
    },
    methods: {
      switchPosition() {
        this.post.position = this.post.position === 0 ? 1 : 0;
      },
      switchVisible() {
        this.post.visible = this.post.visible === 1 ? 0 : 1;
      },
      initPost(alias) {
        getPostByAlias(alias).then(res => {
          if (res.data.code === 0) {
            this.post = res.data.data;
          } else {
            Notify.error(res.data.code + ": " + res.data.msg);
          }
        })
      },
      initCategoryList() {
        getAllCategory().then(res => {
          if (res.data.code === 0) {
            let categoryList = res.data.data;
            categoryList.forEach(item => {
              this.items.categoryList.push({value: item.id, text: item.name});
            })
          } else {
            Notify.error(res.data.code + ": " + res.data.msg);
          }
        })
      },
      initTagItems() {
        getTagList().then(res => {
          if (res.data.code === 0) {
            this.items.existTags = res.data.data;
          } else {
            Notify.error(res.data.code + ": " + res.data.msg);
          }
        })
      },
      publish(status) {
        this.post.status = status;
        let message = this.validatePublishParam();
        if (message != null) {
          Notify.error(message);
          return;
        }
        // console.log(this.post);
        savePost(this.post).then(res => {
          if (res.data.code === 0) {
            Notify.success(res.data.msg);
            this.$router.push('/admin/postManage');
          } else {
            Notify.error(res.data.code + ": " + res.data.msg);
          }
        })
      },
      backspace() {
        this.$router.go(-1);
      },
      validatePublishParam() {
        if (isEmpty(this.post.title)) {
          this.$refs.title.focus();
          return "文章标题不能为空!";
        }
        if (isEmpty(this.post.text)) {
          this.$refs.text.focus();
          return "文章正文不能为空!";
        }
        if (isEmpty(this.post.cate_id)) {
          this.$refs.cate_id.focus();
          return "请选择文章分类!";
        }
        return null;
      },
      tooglePreview(textStatus) {
        this.items.textStatus = textStatus;
      }
    }
  };
</script>
<style>
  .lone-select {
    color: #8898aa;
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 40px;
    line-height: 38px;
    font-size: .875rem;
  }

  .lone-select .selected {
    color: #8898aa;
  }

  .lone-select .selected.focus {
    color: #8898aa;
    border: 1px solid #2dce89;
    border-radius: 4px;
  }


  .lone-select .selected:after {
    position: absolute;
    content: "";
    top: 20px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #8898aa transparent transparent transparent;
  }

  .lone-select .items {
    color: #8898aa;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #e4e7ed;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .lone-select .items div {
    color: #8898aa;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  .lone-select .items div:hover {
    background-color: #f5f7fa;
  }

  .selectHighlight {
    color: #2dce89;
    /* background-color: #409eff; */
    font-weight: 600;
  }

.badge {
  display: inline-block;
  padding: 0.2rem 0.275rem;
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.preview-wrapper {
  background-color: white;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
}

.preview {
  padding: 10px;
}

</style>
